"use client";

import { useEffect, useState } from "react";
import { getLtvValues } from "src/loan/LoanFunctions";
import { Transform } from "shared/build/src/utils/Transform";
import { BigFloat, SerializedBigFloat } from "shared/build/src/utils/BigFloat";

type CalculatorLtvIndicatorProps = {
  currentLtv: number;
  maxLtv: number;
  liquidationLtv: number;
  maxLtvTooltipText: string;
  liqLtvTooltipText: string;
  label?: string;
  ltvValues: ReturnType<typeof getLtvValues>;
};

export function CalculatorLtvIndicatorForServerComponents(
  props: Transform<BigFloat, SerializedBigFloat, CalculatorLtvIndicatorProps>
) {
  const deserializedProps = BigFloat.deepDeserialize(props);

  return <CalculatorLtvIndicator {...deserializedProps} />;
}

// TODO: use ltvValues
export function CalculatorLtvIndicator(props: CalculatorLtvIndicatorProps) {
  const { currentLtv, maxLtv, liquidationLtv, label } = props;

  const maxIndicatorId = "max-ltv-indicator";
  const liqIndicatorId = "liquidation-ltv-indicator";

  const [showMaxTooltip, setShowMaxTooltip] = useState(false);
  const [showLiqLtvTooltip, setShowLiqLtvTooltip] = useState(false);

  const [maxTooltipHover, setMaxTooltipHover] = useState(false);
  const [liqLtvTooltipHover, setLiqLtvTooltipHover] = useState(false);

  // detects clicks outside of tooltips
  useEffect(() => {
    window.addEventListener("click", (e) => {
      const maxIndicator = document.getElementById(maxIndicatorId);
      const liqIndicator = document.getElementById(liqIndicatorId);

      if (e.target !== maxIndicator) {
        setShowMaxTooltip(false);
      }

      if (e.target !== liqIndicator) {
        setShowLiqLtvTooltip(false);
      }
    });
  }, []);

  const barSize = Math.min(currentLtv, 100);
  const barColor = getLtvLevel(currentLtv, props.ltvValues);

  const titleColor =
    barColor !== "success" ? "text-danger-text" : "text-[#375C4C]";

  const maxLtvPos = Math.min(maxLtv, 100);

  const liquidationLtvPos = Math.min(liquidationLtv, 100);

  return (
    <div
      id="bar-background"
      className="relative flex h-[0.625rem]  w-full flex-row items-center rounded-full bg-gray-300"
    >
      <div
        id="ltv-bar"
        style={{
          width: barSize + "%",
        }}
        className={`absolute h-[0.625rem] rounded-full  bg-${barColor}`}
      />
      {label && (
        <div
          id="title-labels"
          className={`absolute flex h-3 w-full flex-col items-start `}
        >
          <label
            id="upper-title"
            className={`absolute -top-4 text-center font-sans text-xs ${titleColor}`}
          >
            {label}
          </label>
        </div>
      )}

      {maxLtv && liquidationLtv ? (
        <>
          {currentLtv && (
            <IndicatorTooltip
              id="current-ltv-indicator"
              className="border-2 border-transparent bg-transparent"
              name="CURRENT"
              value={currentLtv > 100 ? ">100" : currentLtv}
              pos={currentLtv >= 100 ? 94 : barSize}
              textColor={"text-" + barColor}
            />
          )}
          <IndicatorTooltip
            id={maxIndicatorId}
            className="border-2 border-white bg-slate-400 hover:bg-[#384865]"
            name="MAX"
            value={maxLtv}
            pos={maxLtvPos}
            showTooltip={showMaxTooltip}
            onClick={() => {
              if (!maxTooltipHover) setShowMaxTooltip(!showMaxTooltip);
            }}
            onMouseEnter={() => {
              setMaxTooltipHover(true);
              setShowMaxTooltip(true);
            }}
            onMouseLeave={() => {
              setMaxTooltipHover(false);
              setShowMaxTooltip(false);
            }}
            tooltipText={props.maxLtvTooltipText}
            tooltipTitle={`Maximum LTV`}
          />
          <IndicatorTooltip
            id={liqIndicatorId}
            className="border-2 border-white bg-slate-400 hover:bg-[#384865]"
            name="LIQ"
            value={liquidationLtv}
            pos={liquidationLtvPos}
            showTooltip={showLiqLtvTooltip}
            onClick={() => {
              if (!liqLtvTooltipHover) setShowLiqLtvTooltip(!showLiqLtvTooltip);
            }}
            onMouseEnter={() => {
              setLiqLtvTooltipHover(true);
              setShowLiqLtvTooltip(true);
            }}
            onMouseLeave={() => {
              setLiqLtvTooltipHover(false);
              setShowLiqLtvTooltip(false);
            }}
            tooltipText={props.liqLtvTooltipText}
            tooltipTitle="Liquidation LTV"
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
}

type IndicatorTooltipProps = {
  pos: number;
  value: number | string;
  name: string;
  textColor?: string;
  showTooltip?: boolean;
  tooltipText?: string;
  tooltipTitle?: string;
} & JSX.IntrinsicElements["div"];

function IndicatorTooltip(props: IndicatorTooltipProps) {
  const {
    pos,
    value,
    name,
    className,
    showTooltip,
    tooltipText,
    tooltipTitle,
    textColor,
    ...divProps
  } = props;

  return (
    <div
      style={{
        transform: "translate(-50%)",
        left: `${pos}%`,
      }}
      className={
        "absolute flex h-3 w-3 flex-col items-center rounded-full " + className
      }
      {...divProps}
    >
      {showTooltip && (
        <div className="pointer-events-none absolute bottom-6 z-50 -ml-32 xl:-ml-24 ">
          <div className="flex min-w-[20rem] flex-col gap-2 rounded-sm bg-[#384865] p-4 text-xs text-white">
            <div className="flex flex-row justify-between font-semibold">
              <h4>{tooltipTitle}</h4>
              <p>{value}%</p>
            </div>
            <p>{tooltipText}</p>
          </div>
          <div className="mt-[-9px] ml-[67.5%] h-4 w-4 rotate-45 bg-[#384865] xl:ml-[62.2%] " />
        </div>
      )}

      {textColor && (
        <label
          id="percentage"
          className={
            "absolute top-4 text-center font-sans text-sm font-semibold " +
            textColor
          }
        >
          {value}%
        </label>
      )}
    </div>
  );
}

export function getLtvLevel(
  currentLtv: number,
  ltvValues: ReturnType<typeof getLtvValues>
) {
  if (currentLtv <= ltvValues.FAIR.formatAndRound() * 100) return "success";
  if (currentLtv <= ltvValues.POOR.formatAndRound() * 100) return "warning";
  return "danger";
}
