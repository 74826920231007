import * as zod from "zod";
import { BigFloat } from "../utils/BigFloat";
import { LoanStatus } from "@prisma/client";
export { LoanStatus };
export const newLoanRequestSchema = zod.object({
    userId: zod.string(),
    adapterId: zod.string(),
    collateral: zod.string(),
    loanAmount: zod.string(),
    exchangeRate: zod.string(),
    collateralCurrency: zod.string(),
    loanCurrency: zod.string(),
});
export const newLoanResponseSchema = zod.object({
    loanId: zod.string(),
});
export const closeLoanRequestSchema = zod.object({
    loanId: zod.string(),
    userId: zod.string(),
});
export const closeLoanResponseSchema = zod.object({
    loanId: zod.string(),
});
export const addCollateralRequestSchema = zod.object({
    loanId: zod.string(),
    userId: zod.string(),
    collateral: zod.custom(BigFloat.isSerializedBigFloat),
});
export const removeCollateralRequestSchema = zod.object({
    loanId: zod.string(),
    userId: zod.string(),
    collateral: zod.custom(BigFloat.isSerializedBigFloat),
});
export const addDebtRequestSchema = zod.object({
    loanId: zod.string(),
    userId: zod.string(),
    debt: zod.custom(BigFloat.isSerializedBigFloat),
});
export const removeDebtRequestSchema = zod.object({
    loanId: zod.string(),
    userId: zod.string(),
    debt: zod.custom(BigFloat.isSerializedBigFloat),
});
