export type IconsLeaf = {
  path: string;
  width: number;
  height: number;
  alt: string;
};

type Icons = {
  [key: string]: IconsLeaf;
};

export const assets = {
  DAI: {
    path: "/images/assets/dai.svg",
    width: 22,
    height: 22,
    alt: "Dai coin logo",
  },
  ETH: {
    path: "/images/assets/eth.svg",
    width: 22,
    height: 22,
    alt: "Eth (ethereum) coin logo",
  },
  wBTC: {
    path: "/images/assets/btc.svg",
    width: 22,
    height: 22,
    alt: "Btc (bitcoin) coin logo",
  },
  NOK: {
    path: "/images/assets/nok.svg",
    width: 22,
    height: 22,
    alt: "NOK (norwegian crowns) currency logo",
  },
  USD: {
    path: "/images/assets/usd.svg",
    width: 22,
    height: 22,
    alt: "USD (United States dollar) currency logo",
  },
  EUR: {
    path: "/images/assets/eur.svg",
    width: 22,
    height: 22,
    alt: "Eur (euro) coin logo",
  },
  CHF: {
    path: "/images/assets/chf.svg",
    width: 22,
    height: 22,
    alt: "Chf (swiss franc) coin logo",
  },
  GBP: {
    path: "/images/assets/gbp.svg",
    width: 22,
    height: 22,
    alt: "Gpb (british pound) coin logo",
  },
  SEK: {
    path: "/images/assets/sek.svg",
    width: 22,
    height: 22,
    alt: "Swedish krone coin logo",
  },
  DKK: {
    path: "/images/assets/dkk.svg",
    width: 22,
    height: 22,
    alt: "Danish krone coin logo",
  },
} satisfies Icons;

export const icons = {
  square_logo: {
    path: "/images/logo/square_logo.svg",
    width: 140,
    height: 72,
    alt: "Vanir finance logo with square border",
  },
  currencySelectorButton: {
    path: "/images/icons/currency-selector-button.svg",
    width: 10,
    height: 14,
    alt: "Currency selector button for dropdown menu",
  },
  currencySelectorButtonBlack: {
    path: "/images/icons/currency-selector-button-black.svg",
    width: 10,
    height: 14,
    alt: "Currency selector button for dropdown menu",
  },
  LOGO: {
    path: "/images/logo/VANIR.svg",
    width: 184,
    height: 74,
    alt: "Site logo in norse font",
  },
  nr1: {
    path: "/images/icons/numbers/1.svg",
    width: 9,
    height: 19,
    alt: "Number 1",
  },
  nr2: {
    path: "/images/icons/numbers/2.svg",
    width: 13,
    height: 19,
    alt: "Number 2",
  },
  nr3: {
    path: "/images/icons/numbers/3.svg",
    width: 12,
    height: 19,
    alt: "Number 3",
  },
  myLoans: {
    path: "/images/icons/my-loans.svg",
    width: 20,
    height: 20,
    alt: "My loans",
  },
  triangle: {
    path: "/images/icons/triangle.svg",
    width: 24,
    height: 24,
    alt: "Triangle pointer",
  },
  triangle_grey: {
    path: "/images/icons/triangle_grey.svg",
    width: 24,
    height: 24,
    alt: "Triangle pointer",
  },
  triangle_blue: {
    path: "/images/icons/triangle_blue.svg",
    width: 24,
    height: 24,
    alt: "Triangle pointer",
  },
  welcome_1: {
    path: "/images/icons/welcome_1.svg",
    width: 90,
    height: 90,
    alt: "Exchange",
  },
  welcome_2: {
    path: "/images/icons/welcome_2.svg",
    width: 90,
    height: 90,
    alt: "Transfer",
  },
  welcome_3: {
    path: "/images/icons/welcome_3.svg",
    width: 90,
    height: 90,
    alt: "Interest rate",
  },
  info: {
    path: "/images/info.svg",
    width: 9,
    height: 9,
    alt: "Tooltip info icon",
  },
  blackArrow: {
    path: "/images/icons/arrow-black.svg",
    width: 20,
    height: 35,
    alt: "Black arrow icon",
  },
  idCard: {
    path: "/images/icons/identity-card-1.svg",
    width: 51,
    height: 51,
    alt: "Identity card icon",
  },
  checkboardWithPen: {
    path: "/images/icons/checkboard-with-pen.svg",
    width: 50,
    height: 50,
    alt: "Checkboard with pen",
  },
  lockLocked: {
    path: "/images/icons/lock-locked.svg",
    width: 40,
    height: 40,
    alt: "Locked padlock",
  },
  lockOpen: {
    path: "/images/icons/padlock-open.svg",
    width: 40,
    height: 40,
    alt: "Open padlock",
  },
  scanningDocs: {
    path: "/images/icons/scanning-documents.svg",
    width: 50,
    height: 50,
    alt: "Scanning documents",
  },
  mail: {
    path: "/images/icons/mail.svg",
    width: 45,
    height: 45,
    alt: "Mail",
  },
  smsBubble: {
    path: "/images/icons/sms-bubble.svg",
    width: 45,
    height: 45,
    alt: "Text bubble",
  },
  dashboard: {
    path: "/images/icons/dashboard-1.svg",
    width: 23,
    height: 23,
    alt: "Dashboard icon",
  },
  loan: {
    path: "/images/icons/loan-1.svg",
    width: 24,
    height: 24,
    alt: "Loans icon",
  },
  account: {
    path: "/images/icons/wallet-1.svg",
    width: 24,
    height: 24,
    alt: "Accounts icon",
  },
  helpBubble: {
    path: "/images/icons/help-bubble.svg",
    width: 21,
    height: 21,
    alt: "Help icon",
  },
  user: {
    path: "/images/icons/user.svg",
    width: 21,
    height: 21,
    alt: "User profile icon",
  },
  verify: {
    path: "/images/icons/files-and-folders-1.svg",
    width: 21,
    height: 21,
    alt: "Verify profile icon",
  },
  communcation: {
    path: "/images/icons/communication.svg",
    width: 31,
    height: 31,
    alt: "Verify SMS and email icon",
  },
} satisfies Icons;

export const backgrounds = {
  k33: {
    path: "/images/backgrounds/k33-welcome.svg",
    width: 1920,
    height: 1011,
    alt: "Abstract landscape painting",
  },
} satisfies Icons;
