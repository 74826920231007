"use client";

import { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";

export interface ModalProps {
  children: React.ReactNode;
  show: boolean;
  onClose: () => void;
}

/**
 *
 * @warning Do not use directly except in storybook
 */
export function ModalWrapper(props: ModalProps) {
  return (
    <div
      onClick={() => props.onClose()}
      className={`fixed top-0 left-0 z-50 flex h-full w-full items-end justify-center bg-modal-background bg-opacity-50 md:items-center`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="relative flex w-full flex-col gap-y-11 rounded-t-xl bg-modal-modal py-8 px-5 md:w-fit md:min-w-modal md:rounded-xl md:p-16"
      >
        {props.children}
      </div>
    </div>
  );
}

export function Modal(props: ModalProps): ReactNode {
  const [modalRoot, setModalRoot] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const modalRoot = document.getElementById("modal-root");
    setModalRoot(modalRoot as HTMLDivElement);
  }, []);

  if (!props.show) return null;

  if (!modalRoot) {
    console.error(new Error("Modal root not found"));
    return null;
  }

  return createPortal(
    <ModalWrapper onClose={props.onClose} show={props.show}>
      {props.children}
    </ModalWrapper>,
    modalRoot
  );
}
